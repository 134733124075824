import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, Container, Image } from "react-bootstrap"
import FontAwesome from "./FontAwesome"
import FAB from "../fab/Fab"

const RedirectPage = ({path}) => {
  React.useEffect(() => {
    window.location.replace(path)
  }, [])
}

class Footer extends React.Component {
  render() {
    return (
      <>
        {/* <section className="section pt-5 pb-5 text-center bg-white">
			         <Container>
			            <Row>
			               <Col sm={12}>
			                  <h5 className="m-0">Operate food store or restaurants? <Link to="login">Work With Us</Link></h5>
			               </Col>
			            </Row>
			         </Container>
			    </section> */}
        <FAB />
        <section className="footer pt-5 pb-5">
          <Container>
            <Row>
              <Col md={4} sm={12}>
                <Link to="/">
                  <Image src="/img/lunchbox-logo.png" height={50} alt="" />
                </Link>
                <p style={{ fontWeight: 300 }}>
                  Nous proposons des solutions de restauration collective. Des
                  menus, riches et équilibrés, sont confectionnés avec beaucoup
                  d’amour à partir d’ingrédients exclusivement frais. Parce
                  qu’au bureau aussi, vous méritez de mieux manger!
                </p>
              </Col>
              <Col md={1} sm={6} className="mobile-none"></Col>
              <Col md={3} sm={4} xs={6}>
                <h6 className="mb-3">Lunchbox</h6>
                <ul>
                  <li>
                    <Link to="#">A propos de nous</Link>
                  </li>
                  <li>
                    <a href='https://wa.me/23877524' target='_blank' rel="noopener noreferrer">Contact</a>
                  </li>
                </ul>
              </Col>
              <Col md={3} sm={4} xs={6}>
                <h6 className="mb-3">Communauté</h6>
                <ul>
                  <li>
                    <Link to="#">Termes et conditions</Link>
                  </li>
                </ul>
              </Col>
              {/* <Col md={2} sm={4} xs={4} className="m-none">
                <h6 className="mb-3">For Restaurants</h6>
                <ul>
                  <li>
                    <Link to="#">Advertise</Link>
                  </li>
                  <li>
                    <Link to="#">Add a Restaurant</Link>
                  </li>
                  <li>
                    <Link to="#">Claim your uling</Link>
                  </li>
                  <li>
                    <Link to="#">For Businesses</Link>
                  </li>
                  <li>
                    <Link to="#">Owner Guidelines</Link>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </Container>
        </section>
        <footer className="pt-4 pb-4 text-center">
          <Container>
            <p className="mt-0 mb-0">{this.props.copyrightText}</p>
            <small className="mt-0 mb-0">
              {" "}
              Made with <FontAwesome
                icon={this.props.madewithIconclassName}
              />{" "}
              by{" "}
              <Link
                className="text-primary"
                target="_blank"
                to={this.props.secondLink}
              >
                {this.props.secondLinkText}
              </Link>
            </small>
          </Container>
        </footer>
      </>
    )
  }
}

Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName: PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName: PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLink: PropTypes.string
}

Footer.defaultProps = {
  sectionclassName: "footer-bottom-search pt-5 pb-5 bg-white",
  popularCHclassName: "text-black",
  popularCountries: [],
  popularFHclassName: "mt-4 text-black",
  popularFood: [],
  copyrightText: `© Copyright ${new Date().getFullYear()} LunchBox. All Rights Reserved`,
  madewithIconclassName: "heart heart-icon text-danger",
  firstLinkText: "Shady Achouri",
  firstLink: "#",
  secondLinkText: "DevWorks Solutions",
  secondLink: "#"
}

export default Footer
