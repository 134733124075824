import React, { useEffect, useState } from "react"
import { Row, Col, Container, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import OwlCarousel from "react-owl-carousel3"
import TopSearch from "./home/TopSearch"
import CardItem from "./common/CardItem"
import SectionHeading from "./common/SectionHeading"
import FontAwesome from "./common/FontAwesome"
import Category from "./common/Category"
import { useQuery } from "react-query"
import { host, zones } from "../config"
import axios from "axios"
import { useRecoilState } from "recoil"
import { expiredAtom, menuAtom, orderAtom } from "../recoil/atoms"
import moment from "moment"
import { checkIfValid } from "../helpers"
import ChooseAddressCard from "./common/ChooseAddressCard"

const Index = () => {
  const [order, setOrder] = useRecoilState(orderAtom)
  const [menu, setMenu] = useRecoilState(menuAtom)
  const [expired, setExpired] = useRecoilState(expiredAtom)
  const [relays, setRelays] = useState([])

  const useGetActiveMenu = () => {
    return useQuery("getActiveMenu", async () => {
      return await axios.get(`${host}/admin-api/v1/menu/active`)
    })
  }

  const { data: activeMenuData, isLoading } = useGetActiveMenu()

  useEffect(() => {
    if (activeMenuData) {
      setMenu({ ...activeMenuData.data })
    }
  }, [activeMenuData])

  useEffect(() => {
    let notValid = checkIfValid()
    setExpired(notValid)
  }, [])

  const useGetRelayPoints = () => {
    return useQuery("getRelayPoints", async () => {
      return await axios.post(`${host}/admin-api/v1/relay-points/list`)
    }, {
      onSuccess: (data) => {
        setRelays(data?.data)
      }
    })
  }

  useGetRelayPoints();

  return (
    <>
      <TopSearch />
      {expired && <Row>
        <Col md={3} />
        <Col md={6} className="text-center">
          <div
            className="p-2 mt-5"
            style={{ border: "1px solid #444", borderRadius: 10 }}
          >
            <h5 className="m-0">
              N.B : Les commandes sur le site sont clôturées mais vous pouvez toujours trouver nos LunchBox chez nos points de retrait
            </h5>
          </div>
        </Col>
      </Row>}
      <section className="section pb-5 products-section">
        {/* <Col md={12} className="text-center">
          <h3 style={{ marginTop: 30, fontWeight: 'bold' }}>Cher client, Lunchbox retrouvera une activité normale le Jeudi 27 Juillet 2023.</h3>
          <h3 style={{ marginTop: 30, fontWeight: 'bold' }}>Merci pour votre fidélité.</h3>
        </Col> */}
        {isLoading && (
          <Col md={12} className="text-center pt-5">
            <Spinner variant="danger" animation="border" />
          </Col>
        )}
        {!isLoading &&
          activeMenuData.data.categories.map((category, key) => (
            <Category category={category} key={key} />
          ))}
      </section>
      {expired && <section className="section pb-5 products-section">
        <Container>
          <h4 className="mb-3">Retrouvez nos Lunchbox chez nos points de retrait</h4>
          <Row>
            {relays.map((relay, key) => (
              <Col key={key} md={6}>
                <ChooseAddressCard
                  selected={false}
                  onClick={() => { return; }}
                  boxclassName="border border-success"
                  title={relay?.name}
                  address={relay?.address}
                  icoIcon="map"
                  isDefault={true}
                  iconclassName="icofont-3x"
                  zone={zones.find(el => el.value === relay?.zone)?.label}
                  mapsLink={relay?.mapsLink}
                  type="relay"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>}
      <section className="section pt-5 pb-5 bg-white becomemember-section border-bottom">
        <Container>
          <SectionHeading
            heading="Testez l'expérience LunchBox"
            subHeading="Vous ne le regretterez pas"
          />
          <Row>
            <Col sm={12} className="text-center">
              <Link to="register" className="btn btn-success btn-lg">
                Créer un compte <FontAwesome icon="chevron-circle-right" />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

const options = {
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 2
    },
    1000: {
      items: 4
    },
    1200: {
      items: 4
    }
  },

  lazyLoad: true,
  pagination: false.toString(),
  loop: true,
  dots: false,
  autoPlay: 2000,
  nav: true,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>"
  ]
}

export default Index
