import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import AddAddressModal from "../modals/AddAddressModal"
import DeleteAddressModal from "../modals/DeleteAddressModal"
import AddressCard from "../common/AddressCard"
import { useLocalStorage } from "@mantine/hooks"
import { zones } from "../../config"
import { useRecoilState } from "recoil"
import { clientAtom } from "../../recoil/atoms"

const Addresses = () => {
  const [client, setClient] = useRecoilState(clientAtom);

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState();

  const hideDeleteModal = () => setShowDeleteModal(false)
  const hideAddressModal = () => setShowAddressModal(false)

  return (
    <>
      <AddAddressModal show={showAddressModal} onHide={hideAddressModal} selectedAddress={selectedAddress} />
      <DeleteAddressModal show={showDeleteModal} onHide={hideDeleteModal} adressesNumber={client?.adresses?.length} selectedAddress={selectedAddress} />
      <div className="p-4 bg-white shadow-sm">
        <Row>
          <Col md={12}>
            <h4 className="font-weight-bold mt-0 mb-3">Gérer mes adresses</h4>
          </Col>
          {client?.adresses?.map((adress, key) => (
            <Col key={key} md={6}>
              <AddressCard
                boxClass="border border-primary shadow"
                title={adress?.name ? adress?.name : "Adresse par défaut"}
                icoIcon="briefcase"
                iconclassName="icofont-3x"
                address={`${adress.adresse} - ${zones.find(
                  (el) => el.value === client?.adresses[0]?.zone
                ).label
                  }`}
                onEditClick={() => {
                  setSelectedAddress(adress);
                  setShowAddressModal(true);
                }}
                onDeleteClick={() => {
                  setSelectedAddress(adress);
                  setShowDeleteModal(true);
                }}
              />
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}
export default Addresses
