import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import Icofont from "react-icofont"
import { cart, menuAtom } from "../../recoil/atoms"
import { useRecoilState } from "recoil"
import { useQuery } from "react-query"
import axios from "axios"
import { host } from "../../config"

const CheckoutItem = ({ product, itemName, price, priceUnit, id, qty }) => {
  const [cartItems, setCartItems] = useRecoilState(cart)
  const [quantity, setQuantity] = useState(qty)


  const incrementItem = () => {
    if (quantity < product.max) {
      setQuantity(quantity + 1)
      const index = cartItems.findIndex((item) => item._id == id)
      if (index === -1) {
        setCartItems([...cartItems, { ...product, number: quantity + 1, max: product.max }])
      } else {
        const newCartItems = cartItems
        newCartItems[index].number = newCartItems[index].number + 1;
        newCartItems[index].max = product.max;
        setCartItems([...newCartItems])
      }
    }
  }
  const decreaseItem = () => {
    if (quantity <= 0) {
    } else {
      setQuantity(quantity - 1)
      const index = cartItems.findIndex((item) => item._id === id)
      if (index !== -1) {
        let newCartItems = cartItems
        if (newCartItems[index].number === 1) {
          newCartItems = newCartItems.filter((el) => el._id !== id)
        } else {
          newCartItems[index].number = newCartItems[index].number - 1
        }
        setCartItems([...newCartItems])
      }
    }
  }

  return (
    <div className="gold-members p-2 border-bottom">
      <p className="text-gray mb-0 float-right ml-2">
        {price * quantity} {priceUnit}
      </p>
      <span className="count-number float-right">
        <Button
          variant="outline-secondary"
          onClick={decreaseItem}
          className="btn-sm left dec"
        >
          {" "}
          <Icofont icon="minus" />{" "}
        </Button>
        <input
          className="count-number-input"
          type="text"
          value={quantity}
          readOnly
        />
        <Button
          variant="outline-secondary"
          onClick={incrementItem}
          className="btn-sm right inc"
        >
          {" "}
          <Icofont icon="icofont-plus" />{" "}
        </Button>
      </span>
      <div className="media">
        <div className="mr-2">
          <Icofont icon="ui-press" className="text-success food-item" />
        </div>
        <div className="media-body">
          <p className="mt-1 mb-0 text-black">{itemName}</p>
        </div>
      </div>
    </div>
  )
}

CheckoutItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired
}
CheckoutItem.defaultProps = {
  show: true,
  priceUnit: "DT"
}

export default CheckoutItem
