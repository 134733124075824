import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Image } from 'react-bootstrap';

class NotFound extends React.Component {

	render() {
		return (
			<section className="section pt-5 pb-5 osahan-not-found-page">
				<Container>
					<Row>
						<Col md={12} className="text-center pt-5 pb-5">
							<Image className="img-fluid" src="/img/404.png" alt="404" />
							<h1 className="mt-2 mb-2">Page introuvable</h1>
							<p>Oh-oh! Apparemment la page que vous cherchez n'existe pas <br />. Veuillez cliquez ci-dessous pour revenir.</p>
							<Link className="btn btn-primary btn-lg" to="/">RETOURNER À L'ACCUEIL</Link>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}


export default NotFound;