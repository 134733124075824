import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Image, Badge, Button } from "react-bootstrap"
import PropTypes from "prop-types"
import Icofont from "react-icofont"
import { cart, expiredAtom, orderAtom } from "../../recoil/atoms"
import { useRecoilState } from "recoil"

const CardItem = (props) => {
  const [quantity, setQuantity] = useState(props.quantity)
  const [tags, setTags] = useState("")
  const [cartItems, setCartItems] = useRecoilState(cart)
  const [expired, setExpired] = useRecoilState(expiredAtom)

  useEffect(() => {
    if (props.subTitle) {
      let result = ""
      props.subTitle.forEach((element, key) => {
        if (key === 0) {
          result = element.name
        } else {
          result = result + " - " + element.name
        }
      })
      setTags(result)
    }
  }, [props])

  const incrementItem = () => {
    if (quantity >= props.max) {
    } else {
      setQuantity(quantity + 1)
      const index = cartItems.findIndex((item) => item._id == props.product._id)
      if (index === -1) {
        setCartItems([...cartItems, { ...props.product, number: quantity + 1, max: props.max }])
      } else {
        const newCartItems = cartItems
        newCartItems[index].number = newCartItems[index].number + 1
        newCartItems[index].max = props.max;
        setCartItems([...newCartItems])
      }
    }

  }
  const decreaseItem = () => {
    if (quantity <= 0) {
    } else {
      setQuantity(quantity - 1)
      const index = cartItems.findIndex(
        (item) => item._id === props.product._id
      )
      if (index !== -1) {
        let newCartItems = cartItems
        if (newCartItems[index].number === 1) {
          newCartItems = newCartItems.filter(
            (el) => el._id !== props.product._id
          )
        } else {
          newCartItems[index].number = newCartItems[index].number - 1
        }
        setCartItems([...newCartItems])
      }
    }
  }
  return (
    <div className="list-card bg-white h-100 rounded  position-relative shadow-lg my-3">
      <div
        className="list-card-image"
        style={{ overflow: "hidden" }}
      >
        {props.rating ? (
          <div className="star position-absolute">
            <Badge variant="success">
              <Icofont icon="star" /> {props.rating}
            </Badge>
          </div>
        ) : (
          ""
        )}
        {props.heart && (
          <div
            className={`favourite-heart position-absolute ${props.favIcoIconColor}`}
          >
            <Icofont icon="heart" />
          </div>
        )}
        {props.showNew ? (
          <div className="member-plan position-absolute">
            <Badge variant={props.promotedVariant}>Nouveau</Badge>
          </div>
        ) : (
          ""
        )}
        {/* <Image
          src={props.image}
          className={props.imageClass}
          alt={props.imageAlt}
        /> */}
      </div>
      <div className="p-3 position-relative">
        <div className="list-card-body">
          <h3 className="mb-1 text-black">{props.title}</h3>
          {props.description ? <h5 className="text-gray mb-3">{props.description}</h5> : null}
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 16 }}>
            {props.subTitle.map((tag, key) =>
              <p key={key} style={{ padding: 4, marginLeft: 4, marginRight: 4, marginBottom: 2, backgroundColor: '#f8f9fa', borderRadius: 10, width: 'fit-content' }}>{tag.name}</p>
            )}
          </div>
          {props.heating || props.price ? (
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <p className="text-gray m-0 time">
                {props.heating ? (
                  props.heating === "À réchauffer" ? (
                    <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                      <Icofont icon="fire" /> {props.heating}
                    </span>
                  ) : props.heating === "Se mange froid" ? (
                    <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                      <Icofont icon="snow" /> {props.heating}
                    </span>
                  ) : (
                    <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                      <Icofont icon="fire" /> / <Icofont icon="snow" />{" "}
                      {props.heating}
                    </span>
                  )
                ) : (
                  ""
                )}
              </p>
              {props.price ? (
                <div style={{ fontSize: 16 }}>
                  <span className="text-black"> {props.price} DT</span>
                  {props.oldPrice && (
                    <span className="old-price"> {props.oldPrice} DT</span>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {!expired && <>
          {
            props.max <= 0 ? (<h4 className="text-danger">Epuisé</h4>) : quantity === 0 ? (
              <div className="mb-3 d-flex flex-row justify-content-center">
                <span>
                  {<Button
                    variant="outline-secondary"
                    onClick={incrementItem}
                    size="md"
                  >
                    + Ajouter au panier
                  </Button>}
                </span>
              </div>
            ) : (
              <div>
                <div className="mb-3 d-flex flex-row justify-content-center">
                  <span className="count-number">
                    <Button
                      variant="outline-secondary"
                      onClick={decreaseItem}
                      className="btn-md left dec"
                    >
                      {" "}
                      <Icofont icon="minus" size={20} />{" "}
                    </Button>
                    <input
                      className="count-number-input"
                      type="text"
                      value={quantity}
                      readOnly
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={incrementItem}
                      className="btn-md right inc"
                    >
                      {" "}
                      <Icofont icon="icofont-plus" />{" "}
                    </Button>
                  </span>
                </div>
                <p className="text-center">Pour toute commande <span style={{ textDecoration: 'underline' }}>au delà de deux produits</span>, vous avez la possibilité d'être livré(e) gratuitement !</p>
              </div>
            )
          }
        </>}
        {props.offerText ? (
          <div className="list-card-badge">
            <Badge variant={props.offerColor}>OFFER</Badge>{" "}
            <small>{props.offerText}</small>
          </div>
        ) : (
          ""
        )}
      </div>
    </div >
  )
}

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  linkUrl: PropTypes.string,
  offerText: PropTypes.string,
  offerColor: PropTypes.string,
  subTitle: PropTypes.array,
  time: PropTypes.string,
  price: PropTypes.number,
  showPromoted: PropTypes.bool,
  promotedVariant: PropTypes.string,
  favIcoIconColor: PropTypes.string,
  rating: PropTypes.string
}
CardItem.defaultProps = {
  imageAlt: "",
  imageClass: "",
  offerText: "",
  offerColor: "success",
  subTitle: "",
  time: "",
  price: "",
  showPromoted: false,
  promotedVariant: "dark",
  favIcoIconColor: "",
  rating: ""
}

export default CardItem
