import React from "react"
import { Form, Modal, Button } from "react-bootstrap"
import { useLocalStorage } from "@mantine/hooks"

const EditProfileModal = (props) => {
  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: ""
  })

  return (
    <Modal show={props.show} onHide={props.onHide} size="md" centered>
      <Modal.Header closeButton={true}>
        <Modal.Title as="h5" id="edit-profile">
          Modifier mon profil
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="form-row">
            <Form.Group className="col-md-12">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="text"
                defaultValue={connectedClient?.phone?.number}
                placeholder="Entrez votre numéro de téléphone"
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                defaultValue={connectedClient?.email}
                placeholder="Entrez votre email"
              />
            </Form.Group>
            <Form.Group className="col-md-12 mb-0">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="password"
                defaultValue="**********"
                placeholder="Entrez votre mot de passe"
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer className="d-flex flex-row justify-content-between">
        <Button
          type="button"
          onClick={props.onHide}
          variant="outline-primary"
          className="d-flex w-25 text-center justify-content-center"
        >
          ANNULER
        </Button>
        <Button
          type="button"
          variant="primary"
          className="d-flex w-50 text-center justify-content-center"
        >
          SAUVEGARDER
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default EditProfileModal
