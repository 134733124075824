import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Row, Col, Container, Form, Button, Image, Alert } from "react-bootstrap"
import FontAwesome from "./common/FontAwesome"
import { useForm } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { useMutation } from "react-query"
import { host } from "../config"
import axios from "axios"
import { showNotification } from "@mantine/notifications"
import {
    TextInput,
    Group,
    Box,
    PasswordInput,
    LoadingOverlay
} from "@mantine/core"
import { useRecoilState } from "recoil"
import { clientAtom } from "../recoil/atoms"

const ForgotPassword = () => {
    const [visible, setVisible] = useState(false)
    const [accountFound, setAccountFound] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);

    const history = useHistory();

    const form = useForm({
        initialValues: {
            email: "",
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : "Adresse e-mail invalide")
        }
    })

    const getUser = useMutation(
        (data) => axios.post(`${host}/client-api/v1/client/get`, data),
        {
            onMutate: () => {
                setVisible((v) => !v)
            },
            onSuccess: (data, variables, context) => {
                let response = data.data
                setAccountFound(response);
                showNotification({
                    title: `Compte trouvé !`,
                    message: "Entrez maintenant un nouveau mot de passe pour votre compte",
                    color: "green"
                })
                //history.replace('/login')
            },
            onError: (error, variables, context) => {
                console.error(error)
                showNotification({
                    title: "Compte introuvable",
                    message: "Il n'y a aucun compte créé avec cette adresse e-mail",
                    color: "red"
                })
            },
            onSettled: () => {
                setVisible((v) => !v)
            }
        }
    )

    const editPassword = useMutation(
        (data) => axios.post(`${host}/client-api/v1/client/edit-password`, data),
        {
            onMutate: () => {
                setVisible((v) => !v)
            },
            onSuccess: (data, variables, context) => {
                let response = data.data
                showNotification({
                    title: `Mot de passe modifié !`,
                    message: "Vous pouvez maintenant vous connecter à l'aide de votre nouveau mot de passe",
                    color: "green"
                })
                history.replace('/login')
            },
            onError: (error, variables, context) => {
                console.error(error)
                showNotification({
                    title: "Oups...",
                    message: "Une erreur s'est produite. Veuillez réessayer.",
                    color: "red"
                })
            },
            onSettled: () => {
                setVisible((v) => !v)
            }
        }
    )

    const handleSubmit = (values) => {
        if (accountFound) {
            if (values.verification != values.password) {
                setAlertVisible(true)
            } else {
                editPassword.mutate(values)
            }
        } else {
            getUser.mutate(values)
        }
    }
    return (
        <Container fluid className="bg-white">
            <Row>
                <Col md={4} lg={5} className="d-none d-md-flex bg-image"></Col>
                <Col md={8} lg={7}>
                    <div className="login d-flex align-items-center py-5">
                        <Container>
                            <Row>
                                <Col md={10} lg={10} className="mx-auto pl-5 pr-5">
                                    <Image
                                        className="mb-2"
                                        src="/img/lunchbox-logo.png"
                                        height={80}
                                        alt="logo"
                                    />

                                    <h3 className="login-heading mb-1">
                                        Réinitialisez votre mot de passe
                                    </h3>
                                    {!accountFound && <h5 className="mb-4 text-black-50">
                                        Entrez l'adresse email de votre compte Lunchbox pour trouver votre compte
                                    </h5>}
                                    {accountFound && <h5 className="mb-4 text-black-50">
                                        Maintenant entrez votre nouveau mot de passe et validez pour récupérer votre compte
                                    </h5>}
                                    <form
                                        style={{ position: "relative" }}
                                        onSubmit={form.onSubmit((values) => {
                                            handleSubmit(values)
                                        })}
                                    >
                                        <LoadingOverlay
                                            visible={visible}
                                            overlayBlur={1}
                                            loaderProps={{ color: "red", variant: "bars" }}
                                        />
                                        <TextInput
                                            disabled={accountFound}
                                            styles={{
                                                input: { marginLeft: 0, marginRight: 0, width: "100%" }
                                            }}
                                            required
                                            label="Email"
                                            placeholder="Entrez l'email de votre compte Lunchbox"
                                            {...form.getInputProps("email")}
                                        />
                                        {accountFound && <>
                                            <PasswordInput
                                                styles={{
                                                    innerInput: {
                                                        marginLeft: 0,
                                                        marginRight: 0,
                                                        width: "100%",
                                                        textAlign: "left",
                                                        borderRadius: 5
                                                    }
                                                }}
                                                placeholder="Entrez le nouveau mot de passe de votre compte"
                                                label="Votre nouveau mot de passe"
                                                required
                                                onKeyDown={() => setAlertVisible(false)}
                                                {...form.getInputProps("password")}
                                            />
                                            <PasswordInput
                                                styles={{
                                                    innerInput: {
                                                        marginLeft: 0,
                                                        marginRight: 0,
                                                        width: "100%",
                                                        textAlign: "left",
                                                        borderRadius: 5
                                                    }
                                                }}
                                                placeholder="Tapez le nouveau mot de passe à nouveau"
                                                label="Tapez le nouveau mot de passe à nouveau"
                                                required
                                                onKeyDown={() => setAlertVisible(false)}
                                                {...form.getInputProps("verification")}
                                            />
                                        </>}
                                        {!accountFound && <Button
                                            type='submit'
                                            className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 mt-2"
                                        >
                                            Trouver mon compte
                                        </Button>}
                                        {alertVisible && <Alert variant="danger" className="mt-3">Les deux mots de passes doivent être identiques</Alert>}
                                        {accountFound && <Button
                                            type='submit'
                                            className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 mt-2"
                                        >
                                            Réinitialiser votre mot de passe
                                        </Button>}
                                    </form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ForgotPassword
