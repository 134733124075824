import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Image, Media } from "react-bootstrap"
import Icofont from "react-icofont"

class OrderCard extends React.Component {
  render() {
    return (
      <div className="bg-white card mb-4 order-list shadow-sm">
        <div className="gold-members p-4">
          <Media>
            <Media.Body>
              <span className="float-right text-info">
                <Icofont icon="location-arrow" /> {this.props.address}
              </span>
              <h6 className="mb-2 text-black">
                {this.props.orderTitle}{" "}
              </h6>
              <p className="text-gray mb-1"></p>
              {this.props.orderProducts.map((orderProduct, key) => (
                <p key={key} className="text-dark" style={{ marginBottom: 5 }}>
                  {orderProduct.product.name} x {orderProduct.number}
                </p>
              ))}
              <hr />
              {(this.props.orderRemise && this.props.orderRemise != 0) ? <p className="mb-0 text-black text-primary pt-2" style={{ fontSize: 18 }}>
                <span className="text-black font-weight-bold">
                  {" "}
                  Remise:
                </span>{" "}
                {`${this.props.orderRemise} DT`}
              </p> : null}
              <p className="mb-0 text-black text-primary pt-2" style={{ fontSize: 18 }}>
                <span className="text-black font-weight-bold">
                  {" "}
                  Total payé:
                </span>{" "}
                {this.props.orderTotal}
              </p>
            </Media.Body>
          </Media>
        </div>
      </div >
    )
  }
}

OrderCard.propTypes = {
  // image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  // orderNumber: PropTypes.string.isRequired,
  // orderDate: PropTypes.string.isRequired,
  deliveredDate: PropTypes.string,
  orderTitle: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  orderProducts: PropTypes.array.isRequired,
  // helpLink: PropTypes.string.isRequired,
  // detailLink: PropTypes.string.isRequired,
  orderTotal: PropTypes.string.isRequired
}
export default OrderCard
