import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { useRecoilState } from 'recoil';
import { companyAtom } from '../../recoil/atoms';
import { useQuery } from 'react-query';
import axios from 'axios';
import { host } from '../../config';

const TopSearch = () => {
	const [company, setCompany] = useRecoilState(companyAtom)
	let params = useParams()
	const history = useHistory()

	// useEffect(() => {
	// 	if (!params.id) {
	// 		setCompany(null)
	// 	}
	// }, [params])

	const useGetCompany = () => {
		return useQuery(["getCompany", params.id], async () => {
			if (params.id) {
				return await axios.get(`${host}/admin-api/v1/group/info/${params.id}`)
			}
		}, {
			onSuccess: (data) => {
				setCompany(data?.data)
			},
			onError: (data) => {
				history.push('/')
			}
		})
	}

	useGetCompany()


	return (
		<section className="pt-5 pb-5 homepage-search-block position-relative" style={{ backgroundImage: "url('/img/hero.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
			<div className="banner-overlay"></div>
			<Container>
				<Row className="d-flex align-items-center">
					<Col md={8}>
						<div className="homepage-search-title">
							<h1 className="pt-5 mb-3 font-weight-bold">Vous en avez marre de vous ruiner en fast-foods à midi ? Essayez LunchBox !</h1>
							<h5 className="mb-3 text-secondary font-weight-normal">Notre service de livraison de repas frais et healthy vous permettra de manger sainement sans vous ruiner</h5>
							{company ?
								<Row className='my-5'>
									<Col sm={4} md={4} className='text-center d-flex flex-column justify-content-between align-items-center' style={{ height: 230 }}>
										<Image src='/img/commande.png' width={150} height={150} />
										<p className='mt-2 text-black font-weight-light'>Choisissez votre commande</p>
									</Col>
									<Col sm={4} md={4} className='text-center d-flex flex-column justify-content-between align-items-center' style={{ height: 230 }}>
										<Image src='/img/commande-1.png' width={150} height={150} />
										<p className='mt-2 text-black font-weight-light'>Validez-la avant 10h30</p>
									</Col>
									<Col sm={4} md={4} className='text-center d-flex flex-column justify-content-between align-items-center' style={{ height: 230 }}>
										<Image src='/img/cash.png' width={150} height={150} />
										<p className='mt-2 text-black font-weight-light'>Vous serez livré en bas de votre bureau ou chez vous</p>
									</Col>
								</Row> :
								<Row className='my-5'>
									<Col sm={4} md={4} className='text-center d-flex flex-column justify-content-between align-items-center' style={{ height: 230 }}>
										<Image src='/img/commande.png' width={150} height={150} />
										<p className='mt-2 text-black font-weight-light'>Choisissez votre commande</p>
									</Col>
									<Col sm={4} md={4} className='text-center d-flex flex-column justify-content-between align-items-center' style={{ height: 230 }}>
										<Image src='/img/retrait.png' width={120} height={120} />
										<p className='mt-2 text-black font-weight-light'>Choisissez un point de retrait et validez votre commande avant 10h30</p>
									</Col>
									<Col sm={4} md={4} className='text-center d-flex flex-column justify-content-between align-items-center' style={{ height: 230 }}>
										<Image src='/img/cash.png' width={150} height={150} />
										<p className='mt-2 text-black font-weight-light'>Récupérez votre commande au point de retrait à partir de 11 heures</p>
									</Col>
								</Row>}
						</div>

						{/* <h6 className="mt-4 text-shadow font-weight-normal">Lunchbox vous propose une grande variété de cétagories, il y en a pour tous les goûts !</h6> */}
						{/* <CategoriesCarousel /> */}
						{/* <h4 style={{ marginTop: 30, fontWeight: 'bold' }}>Important</h4> */}
						{/* <h6 style={{ color: 'red', fontWeight: 400 }}>Attention, toute commande passée après 10h30 ne sera pas prise en compte. Profitez-en pour vous faire plaisir sans culpabiliser !</h6> */}
					</Col>
					{/* <Col md={4}>
	                  <div className="osahan-slider pl-4 pt-3">
	                     <OwlCarousel nav loop {...options2} className="homepage-ad owl-theme">
	                        <div className="item">
								<ProductBox 
							   		image='https://www.eatthis.com/wp-content/uploads/sites/4/2019/06/deep-dish-pizza-chicago.jpg'
							   		imageClass='img-fluid rounded'
							   		imageAlt='carousel'
							   		linkUrl='listing'
							   	/>
	                        </div>
	                        <div className="item">
								<ProductBox 
							   		image='https://www.eatthis.com/wp-content/uploads/sites/4/2019/06/deep-dish-pizza-chicago.jpg'
							   		imageClass='img-fluid rounded'
							   		imageAlt='carousel'
							   		linkUrl='listing'
							   	/>
	                        </div>
	                        <div className="item">
								<ProductBox 
							   		image='https://www.eatthis.com/wp-content/uploads/sites/4/2019/06/deep-dish-pizza-chicago.jpg'
							   		imageClass='img-fluid rounded'
							   		imageAlt='carousel'
							   		linkUrl='listing'
							   	/>
	                        </div>
	                     </OwlCarousel>
	                  </div>
	               </Col> */}
				</Row>
			</Container>
		</section>
	);
}

const options2 = {
	responsive: {
		0: {
			items: 2,
		},
		764: {
			items: 2,
		},
		765: {
			items: 1,
		},
		1200: {
			items: 1,
		},
	},
	lazyLoad: true,
	loop: true,
	autoplay: true,
	autoplaySpeed: 1000,
	dots: false,
	autoplayTimeout: 2000,
	nav: true,
	navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
	autoplayHoverPause: true,
}

export default TopSearch;