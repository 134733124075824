import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Row, Col, Container, Form, Image, Button, Alert } from "react-bootstrap"
import { useForm } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { useMutation } from "react-query"
import { host, zones } from "../config"
import axios from "axios"
import { showNotification, cleanNotifications } from "@mantine/notifications"
import {
  Anchor,
  Checkbox,
  Group,
  LoadingOverlay,
  NumberInput,
  PasswordInput,
  Select,
  Textarea,
  TextInput
} from "@mantine/core"
import { useRecoilState } from "recoil"
import { clientAtom } from "../recoil/atoms"

const Register = () => {
  const [visible, setVisible] = useState(false)
  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: ""
  })
  const [client, setClient] = useRecoilState(clientAtom);
  const [alertVisible, setAlertVisible] = useState(false);

  const history = useHistory();
  const form = useForm({
    initialValues: {
      email: "",
      lastName: "",
      firstName: "",
      phone: "",
      password: "",
      termsOfService: false,
      zone: "",
      address: "",
      indication: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email invalide"),
      password: (value) =>
        value.length >= 6
          ? null
          : "Le mot de passe doit contenir au moins 6 caractères"
    }
  })

  const registerUser = useMutation(
    (data) => axios.post(`${host}/client-api/v1/client/register`, data),
    {
      onMutate: () => {
        setVisible((v) => !v)
      },
      onSuccess: (data, variables, context) => {
        let response = data.data
        setConnectedClient({ ...response, connected: true })
        setClient({ ...response, connected: true })
        showNotification({
          title: "Bienvenue à bord !",
          message: "Votre compte a été créé avec succès !",
          color: "green"
        })
        history.replace('/')
      },
      onError: (error, variables, context) => {
        console.error(error)
        if (error.response.status === 409) {
          showNotification({
            // title: "Compte existant",
            message: <div>
              <Alert variant="danger">Cet adresse e-mail existe déjà dans notre base de données.</Alert>
              <Button
                onClick={() => {
                  cleanNotifications()
                  history.push('/login')
                }}
                className="btn btn-login font-weight-bold mb-2"
              >
                Aller vers la connexion
              </Button>
            </div>,
            color: "red"
          })
        } else if (error.response.status === 409) {
          showNotification({
            // title: "Numéro de téléphone existant",
            message: <div>
              <Alert variant="danger">Ce numéro de téléphone existe déjà dans notre base de données.</Alert>
            </div>,
            color: "red"
          })
        } else {
          showNotification({
            title: "Oups...",
            message: "Il y a quelque chose qui cloche, veuillez rééssayer.",
            color: "red"
          })
        }

      },
      onSettled: () => {
        setVisible((v) => !v)
      }
    }
  )

  const handleSubmit = (values) => {
    setAlertVisible(false)
    if (Object.values(values).findIndex(el => el == "") != -1) {
      setAlertVisible(true)
    } else {
      registerUser.mutate({
        ...values,
        address: {
          nom: "Adresse par défaut",
          zone: values.zone,
          adresse: values.address,
          indication: values.indication
        }
      })
    }

  }

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col md={4} lg={5} className="d-none d-md-flex bg-image"></Col>
        <Col md={8} lg={7}>
          <div className="login d-flex align-items-center py-5">
            <Container>
              <Row>
                <Col md={10} lg={10} className="mx-auto pl-5 pr-5">
                  <Image
                    className="mb-2"
                    src="/img/lunchbox-logo.png"
                    height={80}
                    alt="logo"
                  />
                  <h3 className="login-heading mb-4">Bienvenu(e) à bord !</h3>
                  <h4 className='mt-2' style={{ color: "black" }}>Informations personnelles</h4>
                  <form
                    style={{ position: "relative" }}
                    onSubmit={form.onSubmit((values) => {
                      handleSubmit(values)
                    })}
                  >
                    <LoadingOverlay
                      visible={visible}
                      overlayBlur={1}
                      loaderProps={{ color: "red", variant: "bars" }}
                    />
                    <TextInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      required
                      label="Nom"
                      placeholder="Entrez votre nom"
                      {...form.getInputProps("lastName")}
                    />
                    <TextInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      required
                      label="Prénom"
                      placeholder="Entrez votre prénom"
                      {...form.getInputProps("firstName")}
                    />
                    <NumberInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      placeholder="Entrez votre numéro"
                      label="Numéro de téléphone"
                      hideControls={true}
                      required
                      {...form.getInputProps("phone")}
                    />
                    <TextInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      required
                      label="Email"
                      placeholder="you@email.com"
                      {...form.getInputProps("email")}
                    />
                    <PasswordInput
                      styles={{
                        innerInput: {
                          marginLeft: 0,
                          marginRight: 0,
                          textAlign: "left",
                          borderRadius: 5
                        }
                      }}
                      placeholder="Entrez le mot de passe de votre compte"
                      label="Mot de passe"
                      description="Le mot de passe doit se composer d'au moins 6 caractères"
                      required
                      {...form.getInputProps("password")}
                    />
                    <h4 className='mt-2' style={{ color: "black" }}>Adresse de livraison</h4>
                    <Textarea
                      required
                      label="Adresse"
                      placeholder="Entrez votre adresse"
                      {...form.getInputProps("address")}
                    />
                    <Select
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      label="Zone de livraison"
                      placeholder="Choisir une zone"
                      searchable
                      nothingFound="Zone introuvable"
                      data={zones}
                      {...form.getInputProps("zone")}
                    />
                    <Textarea
                      label="Indication supplémentaire"
                      placeholder="Si besoin, entrez une indication supplémentaire qui permettrait à nos livreurs de trouver le lieu plus facilement."
                      {...form.getInputProps("indication")}
                    />
                    <Checkbox
                      mt="md"
                      color="red"
                      label={
                        <>
                          J'ai lu et j'accepte les{" "}
                          <Anchor
                            size="sm"
                            href="/termes"
                            target="_blank"
                          >
                            conditions d'utilisation
                          </Anchor>
                        </>
                      }
                      {...form.getInputProps("termsOfService", {
                        type: "checkbox"
                      })}
                    />

                    {alertVisible && <Alert variant="danger">Veuillez remplir tous les champs</Alert>}

                    <Button
                      type="submit"
                      className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2"
                    >
                      Inscription
                    </Button>
                    <div className="text-center pt-3">
                      Vous avez déjà un compte? <br />
                      <Link className="font-weight-bold" to="/login">
                        Connectez-vous
                      </Link>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container >
  )
}

export default Register
