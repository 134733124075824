import React from "react"
import PropTypes from "prop-types"
import Icofont from "react-icofont"
import { Col, Row } from "react-bootstrap"

class CartDropdownItem extends React.Component {
  render() {
    return (
      <Row>
        <Col md={8}>
          <p className="mb-2">
            <Icofont
              icon={this.props.icoIcon}
              className={"mr-1 " + this.props.iconClass}
            />
            {this.props.title}
          </p>
        </Col>
        <Col md={4}>
          <p className="mb-2">
            <span className="float-right text-secondary">
              {this.props.price}
            </span>
          </p>
        </Col>
      </Row>
    )
  }
}

CartDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired
}

CartDropdownItem.defaultProps = {
  title: "",
  price: "",
  icoIcon: "",
  iconclass: ""
}

export default CartDropdownItem
