import React, { useState } from "react"
import { Badge } from "react-bootstrap"
import { cart } from "../../recoil/atoms"
import FontAwesome from "../common/FontAwesome"
import {useRecoilValue} from "recoil"
import { useHistory } from "react-router-dom"

const FAB = () => {
  const cartItems = useRecoilValue(cart)
  const history = useHistory();

  const handleClick = () => {history.push("/checkout")}
  return (
    <>{(window.location.pathname !=='/checkout' && window.location.pathname !=='/thanks' ) && <ul className="fab-container">
      <li onClick={() => cartItems.length > 0 && handleClick()} className="fab-button">
        <FontAwesome
          icon="shopping-basket"
          style={{ color: "#231f20", fontSize: 16 }}
        />
      </li>
      {cartItems.length > 0 && <Badge
        variant="success"
        style={{ position: "absolute", top: "-10px", right: 0 }}
      >
        {cartItems.length}
      </Badge>}
    </ul>}</>
  )
}

export default FAB
