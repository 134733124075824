import React, { useState } from "react"
import { Switch, Route } from "react-router-dom"
import { NavLink, Link } from "react-router-dom"
import { Row, Col, Container, Image } from "react-bootstrap"
import Offers from "./myaccount/Offers"
import Orders from "./myaccount/Orders"
import Favourites from "./myaccount/Favourites"
import Payments from "./myaccount/Payments"
import Addresses from "./myaccount/Addresses"
import EditProfileModal from "./modals/EditProfileModal"
import { useLocalStorage } from '@mantine/hooks'
import Avatar from 'react-avatar';

const MyAccount = () => {
  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: ""
  })
  const [showEditProfile, setShowEditProfile] = useState(false)
  const hideEditProfile = () => setShowEditProfile(false)

  return (
    <>
      <EditProfileModal show={showEditProfile} onHide={hideEditProfile} />
      <section className="section pt-4 pb-4 osahan-account-page">
        <Container>
          <Row>
            <Col md={3}>
              <div className="osahan-account-page-left shadow-sm bg-white h-100">
                <div className="border-bottom p-4">
                  <div className="osahan-user text-center">
                    <div className="osahan-user-media">
                      <Avatar className="mb-1" round={true} name={`${connectedClient?.firstName} ${connectedClient?.lastName}`} />

                      <div className="osahan-user-media-body">
                        <h6 className="mb-2">{connectedClient?.firstName} {connectedClient?.lastName}</h6>
                        <p className="mb-1">{connectedClient?.phone?.number}</p>
                        <p>{connectedClient?.email}</p>
                        <p className="mb-0 text-black font-weight-bold">
                          <Link
                            to="#"
                            onClick={() => setShowEditProfile(true)}
                            className="text-primary mr-3"
                          >
                            <i className="icofont-ui-edit"></i> MODIFIER
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/myaccount/orders"
                    >
                      <i className="icofont-food-cart"></i> Commandes
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/offers"><i className="icofont-sale-discount"></i> Offers</NavLink>
                          </li>
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/favourites"><i className="icofont-heart"></i> Favourites</NavLink>
                          </li>
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/payments"><i className="icofont-credit-card"></i> Payments</NavLink>
                          </li> */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/myaccount/addresses"
                    >
                      <i className="icofont-location-pin"></i> Adresses
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={9}>
              <Switch>
                <Route path="/myaccount/orders" exact component={Orders} />
                {/* <Route path="/myaccount/offers" exact component={Offers} />
                    <Route path="/myaccount/favourites" exact component={Favourites} />
                    <Route path="/myaccount/payments" exact component={Payments} /> */}
                <Route
                  path="/myaccount/addresses"
                  exact
                  component={Addresses}
                />
              </Switch>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default MyAccount
