import React from "react"
import { Link } from "react-router-dom"
import { Card, Media } from "react-bootstrap"
import Icofont from "react-icofont"
import PropTypes from "prop-types"
import { CheckIcon } from "@mantine/core"

const ChooseAddressCard = (props) => {
  return (
    <Card style={{ border: props.selected ? '2px solid #f32129' : '1px solid rgba(0,0,0,.125)' }} className={"bg-white addresses-item mb-2 " + props.boxClass} onClick={props.onClick}>
      <div className="gold-members p-4">
        {props.selected && <p style={{ fontSize: 13, color: '#f32129', textAlign: 'right', margin: 0 }}>Selectionné</p>}
        <Media>
          <div className="mr-3">
            <Icofont
              icon={props.icoIcon}
              className={props.iconclassName}
            />
          </div>
          <div className="media-body">
            <h6 className="mb-1 text-secondary">{props.title}</h6>
            <p className="text-black mb-0">{props.zone}</p>
            <p className="text-black-50 mb-0">{props.address}</p>
            {props.type === "address" && <p className="m-0" style={{ fontWeight: 400 }}>Notre livreur vous contactera quand il sera en chemin</p>}
            <p className="mb-0 text-black font-weight-bold">
              {!props.isDefault ? (
                <>
                  <Link
                    className="btn btn-sm btn-success mr-2"
                    to="#"
                    onClick={props.onDeliverHereClick}
                  >
                    {" "}
                    LIVRER ICI {" "}
                  </Link>
                </>
              ) : (
                null
              )}
            </p>
            {props.mapsLink && <div>
              <Icofont
                icon="map-pins"
                className="icofont-2x mr-2"
              />
              <a href={props.mapsLink} target="_blank">Voir sur Google Maps</a>
            </div>}
          </div>
        </Media>
      </div>
    </Card>
  )
}

ChooseAddressCard.propTypes = {
  title: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclassName: PropTypes.string,
  address: PropTypes.string,
  onDeliverHere: PropTypes.func,
  onAddNewClick: PropTypes.func,
  type: PropTypes.string.isRequired
}

ChooseAddressCard.defaultProps = {
  type: "hasAddress"
}

export default ChooseAddressCard
