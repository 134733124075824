import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class DeleteAddressModal extends React.Component {

	render() {
		return (
			<Modal
				show={this.props.show}
				onHide={this.props.onHide}
				centered
				size="md"
			>
				<Modal.Header closeButton={true}>
					{this.props.adressesNumber > 1 ?
						<Modal.Title as='h5' id="delete-address">Supprimer adresse</Modal.Title> :
						<Modal.Title as='h5' id="delete-address">Suppression impossible</Modal.Title>}
				</Modal.Header>

				<Modal.Body>
					{this.props.adressesNumber > 1 ? <p className="mb-0 text-black">Êtes-vous sûr de vouloir supprimer cette adresse ?</p> : <p className="mb-0 text-black">Vous ne pouvez pas supprimer votre seule adresse</p>}
				</Modal.Body>

				<Modal.Footer>
					{this.props.adressesNumber > 1 ? <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-25 text-center justify-content-center">ANNULER</Button> : <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-25 text-center justify-content-center">RETOUR</Button>}
					{this.props.adressesNumber > 1 && <Button type='button' variant="primary" className='d-flex w-25 text-center justify-content-center'>SUPPRIMER</Button>}
				</Modal.Footer>
			</Modal>
		);
	}
}
export default DeleteAddressModal;