import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap"
import FontAwesome from "./common/FontAwesome"
import { useForm } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { useMutation } from "react-query"
import { host } from "../config"
import axios from "axios"
import { showNotification } from "@mantine/notifications"
import {
  TextInput,
  Group,
  Box,
  PasswordInput,
  LoadingOverlay
} from "@mantine/core"
import { useRecoilState } from "recoil"
import { clientAtom } from "../recoil/atoms"

const Login = () => {
  const [visible, setVisible] = useState(false)
  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: ""
  })
  const [client, setClient] = useRecoilState(clientAtom);

  const history = useHistory();

  const form = useForm({
    initialValues: {
      email: "",
      password: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email")
    }
  })

  const loginUser = useMutation(
    (data) => axios.post(`${host}/client-api/v1/client/login`, data),
    {
      onMutate: () => {
        setVisible((v) => !v)
      },
      onSuccess: (data, variables, context) => {
        let response = data.data
        setConnectedClient({ ...response, connected: true })
        setClient({ ...response, connected: true })
        showNotification({
          title: `Contents de vous revoir ${response.firstName}!`,
          message: "Vous pouvez maintenant passer votre commande",
          color: "green"
        })
        history.replace('/')
      },
      onError: (error, variables, context) => {
        console.error(error)
        showNotification({
          title: "Oups...",
          message: "E-mail ou mot de passe incorrect. Veuillez vérifier.",
          color: "red"
        })
      },
      onSettled: () => {
        setVisible((v) => !v)
      }
    }
  )

  const handleSubmit = (values) => {
    loginUser.mutate(values)
  }
  return (
    <Container fluid className="bg-white">
      <Row>
        <Col md={4} lg={5} className="d-none d-md-flex bg-image"></Col>
        <Col md={8} lg={7}>
          <div className="login d-flex align-items-center py-5">
            <Container>
              <Row>
                <Col md={10} lg={10} className="mx-auto pl-5 pr-5">
                  <Image
                    className="mb-2"
                    src="/img/lunchbox-logo.png"
                    height={80}
                    alt="logo"
                  />

                  <h3 className="login-heading mb-4">
                    Contents de vous revoir !
                  </h3>
                  <form
                    style={{ position: "relative" }}
                    onSubmit={form.onSubmit((values) => {
                      handleSubmit(values)
                    })}
                  >
                    <LoadingOverlay
                      visible={visible}
                      overlayBlur={1}
                      loaderProps={{ color: "red", variant: "bars" }}
                    />
                    <TextInput
                      styles={{
                        input: { marginLeft: 0, marginRight: 0, width: "100%" }
                      }}
                      required
                      label="Email"
                      placeholder="you@email.com"
                      {...form.getInputProps("email")}
                    />
                    <PasswordInput
                      styles={{
                        innerInput: {
                          marginLeft: 0,
                          marginRight: 0,
                          width: "100%",
                          textAlign: "left",
                          borderRadius: 5
                        }
                      }}
                      placeholder="Entrez le mot de passe de votre compte"
                      label="Mot de passe"
                      required
                      {...form.getInputProps("password")}
                    />
                    <Link className="float-right mb-3 mt-1" to={'/reset-password'}>Mot de passe oublié ?</Link>
                    <Button
                      type='submit'
                      className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 mt-2"
                    >
                      Connexion
                    </Button>
                    <div className="text-center pt-3">
                      Vous n'avez pas encore de compte ? <br />
                      <Link className="font-weight-bold" to="/register">
                        Inscrivez-vous
                      </Link>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
