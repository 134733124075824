// exports.host = "http://localhost:5001"
exports.host = "https://api.lunch-box.tn"
exports.zones = [
  { value: "cun", label: "Centre urbain nord" },
  { value: "charguia1", label: "Charguia 1" },
  { value: "charguia2", label: "Charguia 2" },
  { value: "soukra", label: "La Soukra" },
  { value: "ennasr", label: "Cité Ennasr" },
  { value: "technopole-ghazala", label: "Technopole El Ghazela" },
  { value: "lac1", label: "Lac 1" },
  { value: "lac2", label: "Lac 2" },
  { value: "lac3", label: "Lac 3" },
  { value: "lamarsa", label: "La Marsa" },
  { value: "med5", label: "Avenue Mohamed 5" },
  { value: "liberte", label: "Avenue de la liberté" },
  { value: "hb", label: "Avenue Habib Bourguiba" },
  { value: "alainsavary", label: "Avenue Alain Savary" },
  { value: "mutuelleville", label: "Mutuelle ville" },
  { value: "montplaisir", label: "Montplaisir" }
]
