import { LoadingOverlay, Select, Textarea } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Modal, ButtonToolbar, Button, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { host, zones } from '../../config';
import { clientAtom } from '../../recoil/atoms';

const AddAddressModal = ({ show, onHide, selectedAddress }) => {
	const [address, setAddress] = useState();
	const [loading, setLoading] = useState(false);
	const [connectedClient, setConnectedClient] = useLocalStorage({
		key: "connected-client",
		defaultValue: ""
	})
	const [client, setClient] = useRecoilState(clientAtom);

	useEffect(() => {
		setAddress(selectedAddress)
	}, [selectedAddress])

	const updateAddress = useMutation(
		(data) => axios.post(`${host}/client-api/v1/client/edit`, data),
		{
			onMutate: () => {
				setLoading((v) => !v)
			},
			onSuccess: (data, variables, context) => {
				showNotification({
					title: `Adresse modifiée avec succès`,
					color: "green"
				});
				onHide()
				setConnectedClient({ ...connectedClient, ...data.data })
				setClient({ ...client, ...data.data })
			},
			onError: (error, variables, context) => {
				console.error(error)
				showNotification({
					title: "Oups...",
					message: "E-mail ou mot de passe incorrect. Veuillez vérifier.",
					color: "red"
				})
			},
			onSettled: () => {
				setLoading((v) => !v)
			}
		}
	)

	const handleSubmit = () => {
		updateAddress.mutate({ id: connectedClient?._id, address });
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			centered
		>
			<LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: "red", variant: "bars" }} />

			<Modal.Header closeButton={true}>
				<Modal.Title as='h5' id="add-address">Modifier {address?.name ? address?.name : "Adresse par défaut"}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row>
					<Col md={12}>
						<Textarea
							required
							label="Adresse"
							placeholder="Entrez votre adresse"
							value={address?.adresse}
							onChange={({ currentTarget }) => setAddress({ ...address, adresse: currentTarget.value })}
						/>
						<Select
							styles={{ input: { marginLeft: 0, marginRight: 0 } }}
							label="Zone de livraison"
							placeholder="Choisir une zone"
							searchable
							nothingFound="Zone introuvable"
							data={zones}
							value={address?.zone}
							onChange={(value) => setAddress({ ...address, zone: value })}
						/>
						<Textarea
							label="Indication supplémentaire"
							placeholder="Si besoin, entrez une indication supplémentaire qui permettrait à nos livreurs de trouver le lieu plus facilement."
							value={address?.indication}
							onChange={({ currentTarget }) => setAddress({ ...address, indication: currentTarget.value })}
						/>
					</Col>
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button type='button' onClick={onHide} variant="outline-primary" className="d-flex w-25 text-center justify-content-center">ANNULER</Button>
				<Button type='button' onClick={handleSubmit} variant="primary" className='d-flex w-25 text-center justify-content-center'>VALIDER</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default AddAddressModal;