import React from "react"
import OwlCarousel from "react-owl-carousel3"
import { useQuery } from "react-query"
import { host } from "../../config"
import MayLikeItem from "./MayLikeItem"
import axios from "axios"
import { Col, Row } from "react-bootstrap"

const ItemsCarousel = () => {
  const useGetExtras = () => {
    return useQuery("getExtras", async () => {
      return await axios.get(`${host}/admin-api/v1/category/extras`)
    })
  }

  const { data: extrasData, isLoading } = useGetExtras()

  return (
    <div className="offers-interested-carousel">
      <Row>
        {!isLoading &&
          extrasData.data.map((item, key) => (
            <Col xs={6} md={3} sm={4} key={key} className="item">
              <MayLikeItem
                title={item.name}
                price={`${item.price} DT`}
                image={`${host}/public/${item.image}`}
                imageClass="img-fluid"
                imageAlt={item.name}
                product={item}
              />
            </Col>
          ))}
      </Row>
    </div>
  )
}

const options = {
  responsive: {
    0: {
      items: 2
    },
    600: {
      items: 3
    },
    1000: {
      items: 4
    },
    1200: {
      items: 5
    }
  },
  lazyLoad: true,
  pagination: "false",
  loop: true,
  dots: false,
  autoPlay: 2000,
  nav: true,
  navText: [
    "<i class='icofont-thin-left'></i>",
    "<i class='icofont-thin-right'></i>"
  ]
}

export default ItemsCarousel
