import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  Button,
  Tab,
  Nav,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Alert,
  Spinner
} from "react-bootstrap"
import ItemsCarousel from "./common/ItemsCarousel"
import ChooseAddressCard from "./common/ChooseAddressCard"
import CheckoutItem from "./common/CheckoutItem"
import AddAddressModal from "./modals/AddAddressModal"
import Icofont from "react-icofont"
import { useLocalStorage } from "@mantine/hooks"
import { useForm } from "@mantine/form"
import { zones } from "../config"
import { cart, clientAtom, codeAtom, companyAtom, menuAtom, orderAtom } from "../recoil/atoms"
import { useRecoilState } from "recoil"
import { useMutation, useQuery } from "react-query"
import { host } from "../config"
import axios from "axios"
import { cleanNotifications, showNotification } from "@mantine/notifications"
import {
  Anchor,
  Checkbox,
  Group,
  LoadingOverlay,
  NumberInput,
  PasswordInput,
  Select,
  Textarea,
  TextInput
} from "@mantine/core"
import moment from "moment"
import { checkIfValid } from "../helpers"

const Checkout = () => {
  const [formType, setFormType] = useState("login")
  const [visible, setVisible] = useState(false)
  const [loadingVisible, setLoadingVisible] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: ""
  })
  const [client, setClient] = useRecoilState(clientAtom);
  const [cartItems, setCartItems] = useRecoilState(cart)
  const [order, setOrder] = useRecoilState(orderAtom)
  const [menu, setMenu] = useRecoilState(menuAtom)
  const [expired, setExpired] = useState(false)
  const [appliedCode, setAppliedCode] = useState()
  const [codeLoading, setCodeLoading] = useState(false)
  const [codeData, setCodeData] = useState();
  const [discountCode, setDiscountCode] = useRecoilState(codeAtom)
  const [company, setCompany] = useRecoilState(companyAtom)
  const [relays, setRelays] = useState([])
  const [selectedRelay, setSelectedRelay] = useState()
  const [totalCartItems, setTotalCartItems] = useState(0)


  useEffect(() => {
    let notValid = checkIfValid()
    setExpired(notValid)
  }, [])


  const useGetActiveMenu = () => {
    return useQuery("getActiveMenu", async () => {
      return await axios.get(`${host}/admin-api/v1/menu/active`)
    })
  }

  const useGetRelayPoints = () => {
    return useQuery("getRelayPoints", async () => {
      return await axios.post(`${host}/admin-api/v1/relay-points/list`)
    }, {
      onSuccess: (data) => {
        setRelays(data?.data)
      }
    })
  }

  useGetRelayPoints();

  const { data: activeMenuData, isLoading } = useGetActiveMenu()

  useEffect(() => {
    if (activeMenuData) {
      setMenu({ ...activeMenuData.data })
    }
  }, [activeMenuData])

  const history = useHistory()

  const hideAddressModal = () => setShowAddressModal(false)
  const getQty = ({ id, quantity }) => {
    //console.log(id);
    //console.log(quantity);
  }

  const loginForm = useForm({
    initialValues: {
      email: "",
      password: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email")
    }
  })

  //useEffect to calculate the total cart items
  useEffect(() => {
    let total = 0;
    cartItems.forEach(el => total = total + el.number)
    setTotalCartItems(total)
  }, [cartItems])

  const registerForm = useForm({
    initialValues: {
      email: "",
      lastName: "",
      firstName: "",
      phone: "",
      password: "",
      termsOfService: false,
      zone: "",
      address: "",
      indication: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email invalide"),
      password: (value) =>
        value.length >= 6
          ? null
          : "Le mot de passe doit contenir au moins 6 caractères"
    }
  })

  const loginUser = useMutation(
    (data) => axios.post(`${host}/client-api/v1/client/login`, data),
    {
      onMutate: () => {
        setVisible((v) => !v)
      },
      onSuccess: (data, variables, context) => {
        let response = data.data
        setConnectedClient({ ...response, connected: true })
        setClient({ ...response, connected: true })
        showNotification({
          title: `Contents de vous revoir ${response.firstName}!`,
          message: "Vous pouvez maintenant passer votre commande",
          color: "green"
        })
      },
      onError: (error, variables, context) => {
        console.error(error)
        showNotification({
          title: "Oups...",
          message: "E-mail ou mot de passe incorrect. Veuillez vérifier.",
          color: "red"
        })
      },
      onSettled: () => {
        setVisible((v) => !v)
      }
    }
  )

  const handleSubmitLogin = (values) => {
    loginUser.mutate(values)
  }

  const registerUser = useMutation(
    (data) => axios.post(`${host}/client-api/v1/client/register`, data),
    {
      onMutate: () => {
        setVisible((v) => !v)
      },
      onSuccess: (data, variables, context) => {
        let response = data.data
        setConnectedClient({ ...response, connected: true })
        setClient({ ...response, connected: true })
        showNotification({
          title: "Bienvenue à bord !",
          message: "Votre compte a été créé avec succès !",
          color: "green"
        })
      },
      onError: (error, variables, context) => {
        console.error(error)
        if (error.response.status === 409) {
          showNotification({
            // title: "Compte existant",
            message: <div>
              <Alert variant="danger">Cet adresse e-mail existe déjà dans notre base de données.</Alert>
              <Button
                onClick={() => {
                  cleanNotifications()
                  setFormType('login')
                }}
                className="btn btn-login font-weight-bold mb-2"
              >
                Aller vers la connexion
              </Button>
            </div>,
            color: "red"
          })
        } else if (error.response.status === 409) {
          showNotification({
            // title: "Numéro de téléphone existant",
            message: <div>
              <Alert variant="danger">Ce numéro de téléphone existe déjà dans notre base de données.</Alert>
            </div>,
            color: "red"
          })
        } else {
          showNotification({
            title: "Oups...",
            message: "Il y a quelque chose qui cloche, veuillez rééssayer.",
            color: "red"
          })
        }

      },
      onSettled: () => {
        setVisible((v) => !v)
      }
    }
  )

  const handleSubmitRegister = (values) => {
    registerUser.mutate({
      ...values,
      address: {
        nom: "Adresse défaut",
        zone: values.zone,
        adresse: values.address,
        indication: values.indication
      }
    })
  }

  const submitOrder = useMutation(
    (data) => axios.post(`${host}/client-api/v1/order/new`, data),
    {
      onMutate: () => {
        setLoadingVisible(true)
      },
      onSuccess: (data, variables, context) => {
        setCartItems([])
        setOrder({ total: 0 })
        history.replace("thanks")
      },
      onError: (error, variables, context) => {
        if (error.response.data.code === 420) {
          showNotification({
            title: "Stock insuffisant",
            message: `Vous ne pouvez pas acheter plus de ${error.response.data.max} unités pour le ${error.response.data.productName}.
            Veuillez corriger le compteur.`,
            color: "red",
            autoClose: false
          })
        } else {
          showNotification({
            title: "Oups...",
            message: "Une erreur s'est produite. Veuillez rééssayer.",
            color: "red"
          })
        }
      },
      onSettled: () => {
        setLoadingVisible(false)
      }
    }
  )



  const handleSubmit = () => {
    let notValid = checkIfValid();
    setExpired(notValid)
    if (!notValid) {
      let orderProducts = []
      cartItems.map((item) =>
        orderProducts.push({
          product: { ...item },
          number: item.number
        })
      )
      let lastAddressIndex = client?.adresses.length - 1;
      let type = '';
      if (company) {
        type = "group"
      } else if (selectedRelay) {
        type = "relay"
      } else {
        type = "address"
      }

      if (type === "address" && totalCartItems < 3) {
        showNotification({
          title: "Vous devez choisir un point de retrait",
          message: "Veuillez choisir un point de retrait ou commandez au moins 3 plats pour activer la livraison au bureau !",
          color: "red"
        })
      } else {
        submitOrder.mutate({
          client: client?._id,
          total: order.total,
          subtotal: order.subtotal,
          delivery: order.delivery,
          address: client?.adresses[lastAddressIndex]._id,
          menu: menu.id,
          products: orderProducts,
          remise: appliedCode ? (appliedCode.rate * order.total / 100).toFixed(2) : 0,
          discountCode: appliedCode?._id,
          relay: selectedRelay,
          group: company?._id,
          type: type
        })
      }
    } else {
      showNotification({
        title: "Trop tard malheureusement...",
        message: "Vous avez malheureusement validé votre commande après 10h30. Elle ne sera donc pas acceptée.",
        color: "red"
      })
    }
  }

  const applyCode = useMutation(
    (data) => axios.post(`${host}/admin-api/v1/discount-code/apply`, data),
    {
      onMutate: () => {
        setCodeLoading(true)
      },
      onSuccess: (data, variables, context) => {
        let response = data.data
        setAppliedCode(response)
        setOrder({ ...order, remise: (response.rate * order.total / 100).toFixed(2) })
        setDiscountCode(response)
        showNotification({
          title: `Code promo appliqué avec succès`,
          message: "Vous pouvez maintenant continuer votre commande",
          color: "green"
        })
      },
      onError: (error, variables, context) => {
        console.error(error)
        showNotification({
          title: "Code incorrect",
          message: "Le code que vous avez entré est erroné. Veuillez vérifier. ",
          color: "red"
        })
      },
      onSettled: () => {
        setCodeLoading(false)
      }
    }
  )

  const handleSubmitCode = () => {
    if (codeData) {
      applyCode.mutate({ code: codeData.trim().toUpperCase() })
    }
  }

  return (
    <section className="offer-dedicated-body pt-5 mt-4 mb-4 pt-2 pb-2">
      <AddAddressModal show={showAddressModal} onHide={hideAddressModal} />
      <Container>
        <Row>
          {/* <Col md={12}>
            <div className="offer-dedicated-body-left">
              <div className="bg-white rounded shadow-sm p-4 mb-4">
                <h6 className="mb-3">
                  Un petit dessert ? Une petite boisson ?
                </h6>
                <ItemsCarousel />
              </div>
            </div>
          </Col> */}
          {client?.connected && (
            <Col md={6}>
              <div className="bg-white rounded shadow p-4 mb-4">
                {company ? <>
                  <h4 className="mb-1">Voici votre adresse de livraison</h4>
                  <h6 className="mb-3 text-black-50">
                    Le livreur vous contactera dès son arrivée
                  </h6>
                  <Row>
                    <Col md={12}>
                      <ChooseAddressCard
                        selected={true}
                        boxclassName="border border-success"
                        title={company?.name}
                        isDefault={
                          client?.adresses.length === 1 ? true : false
                        }
                        icoIcon="briefcase"
                        iconclassName="icofont-3x"
                        address={zones.find(el => el.value === company?.zone)?.label}
                        type="company"

                      />
                    </Col>
                  </Row>
                </> :
                  <>
                    {totalCartItems > 2 && <>
                      <h4 className="mb-3">Votre adresse de livraison</h4>
                      <Row>
                        {client?.adresses.map((adress, key) => (
                          <Col key={key} md={12}>
                            <ChooseAddressCard
                              selected={order.address === adress._id}
                              onClick={() => {
                                setSelectedRelay(null)
                                setOrder({ ...order, address: adress._id })
                              }}
                              boxclassName="border border-success"
                              title={"Adresse par défaut"}
                              address={adress?.address}
                              isDefault={
                                client?.adresses.length === 1 ? true : false
                              }
                              icoIcon="map-pins"
                              iconclassName="icofont-3x"
                              zone={zones.find(el => el.value === adress?.zone)?.label}
                              type="address"
                            />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                    </>}
                    <h4 className="mb-0">Vous pouvez récupérer votre Lunchbox chez nos points de retrait</h4>
                    <h6 className="mb-3 text-muted">Veuillez choisir le point de retrait le plus proche de vous</h6>
                    <Row>
                      {relays.map((relay, key) => (
                        <Col key={key} md={12}>
                          <ChooseAddressCard
                            selected={selectedRelay === relay._id}
                            onClick={() => {
                              setOrder({ ...order, address: null })
                              setSelectedRelay(relay._id)
                            }}
                            boxclassName="border border-success"
                            title={relay?.name}
                            address={relay?.address}
                            isDefault={
                              client?.adresses.length === 1 ? true : false
                            }
                            icoIcon="map"
                            iconclassName="icofont-3x"
                            zone={zones.find(el => el.value === relay?.zone)?.label}
                            mapsLink={relay?.mapsLink}
                            type="relay"
                          />
                        </Col>
                      ))}
                      {totalCartItems < 3 && <Col md={12}>
                        <h5 className="mt-3 text-black" style={{ textDecoration: 'underline' }}>Sinon au-delà de 3 produits, vous pouvez être livrés directement au bureau ou chez-vous</h5>
                      </Col>}
                    </Row>
                  </>}
                {/* <h6 className="mb-3">
                  Vous avez changé d'adresse ? Mettez à jour votre adresse par ici !
                </h6>
                <Button
                  onClick={() => history.push('/myaccount/addresses')}
                  className="btn btn-block btn-login text-uppercase font-weight-bold mb-2 mt-2"
                >
                  Mettre à jour mon adresse
                </Button> */}
              </div>
            </Col>
          )}
          {!client?.connected && (
            <Col md={6}>
              {formType === "login" && (
                <>
                  <h3 className="login-heading mb-4">Connectez-vous</h3>
                  <form
                    style={{ position: "relative" }}
                    onSubmit={loginForm.onSubmit((values) => {
                      handleSubmitLogin(values)
                    })}
                  >
                    <LoadingOverlay
                      visible={visible}
                      overlayBlur={1}
                      loaderProps={{ color: "red", variant: "bars" }}
                    />
                    <TextInput
                      styles={{
                        input: { marginLeft: 0, marginRight: 0, width: "100%" }
                      }}
                      required
                      label="Email"
                      placeholder="you@email.com"
                      {...loginForm.getInputProps("email")}
                    />
                    <PasswordInput
                      styles={{
                        innerInput: {
                          marginLeft: 0,
                          marginRight: 0,
                          width: "100%",
                          textAlign: "left",
                          borderRadius: 5
                        }
                      }}
                      placeholder="Entrez le mot de passe de votre compte"
                      label="Mot de passe"
                      required
                      {...loginForm.getInputProps("password")}
                    />
                    <Button
                      type="submit"
                      className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2 mt-2"
                    >
                      Connexion
                    </Button>
                    <div className="text-center pt-3">
                      Vous n'avez pas encore de compte ? <br />
                      <Link
                        className="font-weight-bold"
                        onClick={() => setFormType("register")}
                      >
                        Inscrivez-vous
                      </Link>
                    </div>
                  </form>
                </>
              )}
              {formType === "register" && (
                <>
                  <h3 className="login-heading mb-4">Créez un compte</h3>
                  <h4 className="mt-2" style={{ color: "black" }}>
                    Informations personnelles
                  </h4>
                  <form
                    style={{ position: "relative" }}
                    onSubmit={registerForm.onSubmit((values) => {
                      handleSubmitRegister(values)
                    })}
                  >
                    <LoadingOverlay
                      visible={visible}
                      overlayBlur={1}
                      loaderProps={{ color: "red", variant: "bars" }}
                    />
                    <TextInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      required
                      label="Nom"
                      placeholder="Entrez votre nom"
                      {...registerForm.getInputProps("lastName")}
                    />
                    <TextInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      required
                      label="Prénom"
                      placeholder="Entrez votre prénom"
                      {...registerForm.getInputProps("firstName")}
                    />
                    <NumberInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      placeholder="Entrez votre numéro"
                      label="Numéro de téléphone"
                      hideControls={true}
                      required
                      {...registerForm.getInputProps("phone")}
                    />
                    <TextInput
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      required
                      label="Email"
                      placeholder="you@email.com"
                      {...registerForm.getInputProps("email")}
                    />
                    <PasswordInput
                      styles={{
                        innerInput: {
                          marginLeft: 0,
                          marginRight: 0,
                          textAlign: "left",
                          borderRadius: 5
                        }
                      }}
                      placeholder="Entrez le mot de passe de votre compte"
                      label="Mot de passe"
                      description="Le mot de passe doit se composer d'au moins 6 caractères"
                      required
                      {...registerForm.getInputProps("password")}
                    />
                    <h4 className="mt-2" style={{ color: "black" }}>
                      Adresse de livraison
                    </h4>
                    <Textarea
                      required
                      label="Adresse"
                      placeholder="Entrez votre adresse"
                      {...registerForm.getInputProps("address")}
                    />
                    <Select
                      styles={{ input: { marginLeft: 0, marginRight: 0 } }}
                      label="Zone de livraison"
                      placeholder="Choisir une zone"
                      searchable
                      nothingFound="Zone introuvable"
                      data={zones}
                      {...registerForm.getInputProps("zone")}
                    />
                    <Textarea
                      label="Indication supplémentaire"
                      placeholder="Si besoin, entrez une indication supplémentaire qui permettrait à nos livreurs de trouver le lieu plus facilement."
                      {...registerForm.getInputProps("indication")}
                    />
                    <Checkbox
                      mt="md"
                      color="red"
                      label={
                        <>
                          J'ai lu et j'accepte les{" "}
                          <Anchor size="sm" href="/termes" target="_blank">
                            conditions d'utilisation
                          </Anchor>
                        </>
                      }
                      {...registerForm.getInputProps("termsOfService", {
                        type: "checkbox"
                      })}
                    />

                    <Button
                      type="submit"
                      className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2"
                    >
                      Inscription
                    </Button>
                    <div className="text-center pt-3">
                      Vous avez déjà un compte? <br />
                      <Link
                        className="font-weight-bold"
                        onClick={() => setFormType("login")}
                      >
                        Connectez-vous
                      </Link>
                    </div>
                  </form>
                </>
              )}
            </Col>
          )}
          <Col md={6}>
            <div className="generator-bg rounded shadow mb-4 p-4 osahan-cart-item">
              <LoadingOverlay
                visible={loadingVisible}
                overlayBlur={2}
                loaderProps={{ color: "red" }}
              />
              <div className="d-flex mb-4 osahan-cart-item-profile">
                {client?.connected && (
                  <div className="d-flex flex-column">
                    <h6 className="mb-1 text-white">
                      Commande pour {client?.firstName}{" "}
                      {client?.lastName}
                    </h6>
                    <p className="mb-0 text-white">
                      <Icofont icon="location-pin" />{" "}
                      {client?.adresses[0].adresse} -{" "}
                      {
                        zones.find(
                          (el) => el.value === client?.adresses[0].zone
                        ).label
                      }
                    </p>
                  </div>
                )}
                {!client?.connected && (
                  <div className="d-flex flex-column">
                    <h6 className="mb-1 text-white">
                      Récapitulatif de votre commande{" "}
                    </h6>
                    <p className="mb-0 text-white">
                      Vous devez créer un compte pour valider votre commande
                    </p>
                  </div>
                )}
              </div>
              <div className="bg-white rounded shadow mb-2">
                {!isLoading &&
                  cartItems.map((item, key) => (
                    <CheckoutItem
                      key={key}
                      itemName={item.name}
                      price={item.price}
                      priceUnit="DT"
                      id={item._id}
                      qty={item.number}
                      getValue={getQty}
                      product={item}
                    />
                  ))}
              </div>
              <div className="mb-2 bg-white rounded p-2 clearfix">
                {!appliedCode ? <InputGroup className="input-group-sm mb-2">
                  <Form.Control type="text" placeholder="Entez un code promo" onChange={(e) => {
                    setCodeData(e.target.value)
                  }} />
                  <InputGroup.Append>
                    <Button
                      variant="primary"
                      type="button"
                      id="button-addon2"
                      onClick={handleSubmitCode}
                    >
                      {codeLoading ? <Spinner color="white" size="sm" animation="border" /> : <><Icofont icon="sale-discount" /> APPLIQUER</>}
                    </Button>
                  </InputGroup.Append>
                </InputGroup> : <h6 className="m-0">Code promo appliqué : {appliedCode?.code} ({appliedCode?.rate}%)</h6>}
              </div>
              <div className="mb-2 bg-white rounded p-2 clearfix">
                <p className="mb-1">
                  Total achats{" "}
                  <span className="float-right text-dark">
                    {order ? order.subtotal : 0} DT
                  </span>
                </p>
                <p className="mb-1">
                  Frais de livraison
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        La livraison est gratuite avec LunchBox
                      </Tooltip>
                    }
                  >
                    <span className="text-info ml-1">
                      <Icofont icon="info-circle" />
                    </span>
                  </OverlayTrigger>
                  <span className="float-right text-dark">{order.delivery} DT</span>
                </p>
                {appliedCode && <p className="mb-1 text-success">
                  Remise totale
                  <span className="float-right text-success">{(appliedCode.rate * order.total / 100).toFixed(2)} DT</span>
                </p>}
                <hr />
                <h6 className="font-weight-bold mb-0">
                  TOTAL À PAYER{" "}
                  <span className="float-right">
                    {order ? order.total - (appliedCode ? (appliedCode.rate * order.total / 100).toFixed(2) : 0) : 0} DT
                  </span>
                </h6>
                {/* <p className="mt-2 text-black" style={{ textDecoration: 'underline' }}>N.B : Un prélèvement de 10% sera effectué au cas où votre paiement sera en tickets restaurant.</p> */}
              </div>
              <Button
                onClick={handleSubmit}
                variant="secondary"
                className={`btn btn-success btn-block btn-lg ${client?.connected && (!expired && ((selectedRelay || totalCartItems > 2) || company)) ? "" : "disabled"
                  }`}
                style={{ backgroundColor: "#76bd1d !important" }}
              >
                COMMANDER
                <Icofont icon="long-arrow-right" />
                <br />
              </Button>
              {expired && (
                <h5
                  style={{ textAlign: "center", color: "white", marginTop: 10 }}
                >{`N.B : Les commandes pour aujourd'hui ont été clôturées. Vous pourrez passer votre commande pour demain à partir de 18h.`}</h5>
              )}
              {!selectedRelay && totalCartItems < 3 && !company && (
                <h5
                  style={{ textAlign: "center", color: "white", marginTop: 10 }}
                >{`Veuillez selectionner un point de retrait dans la liste`}</h5>
              )}
            </div>
            {/* <div className="pt-2"></div>
            <div className="alert alert-success" role="alert">
              You have saved <strong>$1,884</strong> on the bill
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Checkout
