import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { host } from "../../config"
import { cart } from "../../recoil/atoms"
import CardItem from "./CardItem"
import SectionHeading from "./SectionHeading"
import { useRecoilState } from "recoil"

const Category = ({ category }) => {
  const [cartItems, setCartItems] = useRecoilState(cart)

  return (
    <>
      {category.products.length > 0 && (
        <Container className="mt-5">
          <SectionHeading
            alignClass="text-left"
            heading={category.name}
            subHeading={category.headline}
          />
          <Row>
            {category.products.map((product, key) => (
              <Col key={key} md={6} sm={6} xs={12}>
                {/* <OwlCarousel
                  nav
                  loop
                  {...options}
                  className="owl-carousel-four owl-theme"
                > */}
                <div className="item">
                  <CardItem
                    title={product.name}
                    description={product.description}
                    subTitle={product.tags}
                    imageAlt={product.name}
                    image={`${host}/public/${product.image}`}
                    imageClass="img-fluid item-img"
                    //   offerText="65% off | Use Coupon OSAHAN50"
                    heating={product.heating}
                    price={product.price}
                    oldPrice={product.oldPrice ? product.oldPrice : null}
                    showNew={product.showNew}
                    promotedVariant="light"
                    favIcoIconColor="text-danger"
                    heart={product.heart}
                    product={product}
                    quantity={
                      cartItems.find((el) => el._id === product._id)
                        ? cartItems.find((el) => el._id === product._id).number
                        : 0
                    }
                    max={product.max}
                  />
                </div>
              </Col>
            ))}
            {/* </OwlCarousel> */}
          </Row>
        </Container>
      )}
    </>
  )
}

export default Category
