import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { host } from "../../config"
import OrderCard from "../common/OrderCard"
import axios from "axios"
import { useLocalStorage } from "@mantine/hooks"
import moment from "moment"
import { Spinner } from "react-bootstrap"

const Orders = () => {
  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: ""
  })
  const [isLoading, setIsLoading] = useState(true)
  const [ordersData, setOrdersData] = useState([]);

  const useGetOrders = () => {
    return useQuery(["getOrders", connectedClient], async () => {
      return await axios.post(`${host}/client-api/v1/order/list`, {
        filter: {
          client: connectedClient._id
        }
      })
    }, {
      onSuccess: (data) => {
        setOrdersData(data)
      },
      onSettled: () => {
        setIsLoading(false);
      }
    })
  }

  useGetOrders()

  // const { data: ordersData } = useGetOrders()
  console.log(ordersData)

  return (
    <>
      <div className="p-4 bg-white shadow-sm">
        <h4 className="font-weight-bold mt-0 mb-4">Mes commandes</h4>
        {!isLoading &&
          ordersData?.data?.map((order, key) => (
            <OrderCard
              key={key}
              orderTitle={`Commande du ${moment(order.createdAt).format('DD/MM/YYYY à HH:MM')}`}
              address={connectedClient?.adresses?.find(el => el._id == order?.address)?.adresse}
              orderProducts={order?.products}
              orderRemise={order.remise}
              orderTotal={order?.remise ? `${order?.total - order?.remise} DT` : `${order?.total} DT`}
            />
          ))}
        {isLoading && <div style={{ width: '100%', textAlign: 'center' }}>
          <Spinner size="lg" style={{ color: '#e31d1a' }} animation="border" />
        </div>}
      </div>
    </>
  )
}
export default Orders
