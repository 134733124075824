import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Image, Button } from "react-bootstrap"
import { useRecoilState } from "recoil"
import { cart } from "../../recoil/atoms"

const MayLikeItem = (props) => {
  const [quantity, setQuantity] = useState(0)
  const [cartItems, setCartItems] = useRecoilState(cart)

  useEffect(() => {
    if (cartItems != undefined) {
      const index = cartItems.findIndex((el) => el._id == props.product._id)
      if (index !== -1) {
        console.log(index)
        console.log(cartItems[index])
        setQuantity(cartItems[index].number)
      }
    }
  }, [cartItems])
  const incrementItem = () => {
    setQuantity(quantity + 1)
    const index = cartItems.findIndex((item) => item._id == props.product._id)
    if (index === -1) {
      setCartItems([...cartItems, { ...props.product, number: quantity + 1 }])
    } else {
      const newCartItems = cartItems
      newCartItems[index].number = newCartItems[index].number + 1
      setCartItems([...newCartItems])
    }
  }

  return (
    <div className={"position-relative mall-category-item"}>
      <Button
        onClick={incrementItem}
        className="btn btn-primary btn-sm position-absolute"
      >
        +
      </Button>
      <Image
        src={props.image}
        className={props.imageClass}
        alt={props.imageAlt}
      />
      {props.title ? <h6>{props.title}</h6> : ""}
      {props.price ? <small>{props.price}</small> : ""}
    </div>
  )
}

MayLikeItem.propTypes = {
  onAdd: PropTypes.func,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string
}
MayLikeItem.defaultProps = {
  imageAlt: "",
  image: "",
  imageClass: "",
  boxClass: "mall-category-item",
  title: "",
  price: ""
}

export default MayLikeItem
