/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { NavLink, Link, Redirect, useHistory } from "react-router-dom"
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Image,
  Badge
} from "react-bootstrap"
import DropDownTitle from "../common/DropDownTitle"
import CartDropdownHeader from "../cart/CartDropdownHeader"
import CartDropdownItem from "../cart/CartDropdownItem"
import Icofont from "react-icofont"
import { useRecoilState, useRecoilValue } from "recoil"
import { cart, clientAtom, codeAtom, companyAtom, orderAtom } from "../../recoil/atoms"
import { useLocalStorage } from "@mantine/hooks"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { zones } from "../../config"
import { Feather, X } from "react-feather"

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const cartItems = useRecoilValue(cart)
  const discountCode = useRecoilValue(codeAtom)
  const [order, setOrder] = useRecoilState(orderAtom)
  const [company, setCompany] = useRecoilState(companyAtom)

  const [connectedClient, setConnectedClient] = useLocalStorage({
    key: "connected-client",
    defaultValue: { connected: false }
  })
  const [client, setClient] = useRecoilState(clientAtom);
  const closeMenu = () => {
    setIsNavExpanded(false)
  }

  const history = useHistory();

  const node = useRef()

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      // If clicked outside menu, close the navbar.
      setIsNavExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, false)
  }, [])

  useEffect(() => {
    return () => document.removeEventListener("click", handleClick, false)
  }, [])

  const calculateDeliveryFee = (total) => {
    const min = 2;
    const max = 6;
    const exactFee = total / 10;
    const palier = Math.floor(exactFee / 0.5);
    const fee = (palier + 1) * 0.5

    // if (fee < min) {
    //   return min;
    // }
    // if (fee > max) {
    //   return max
    // }
    return 0
  }

  useEffect(() => {
    let total = 0
    cartItems.forEach((item) => (total = total + item.number * item.price))
    let deliveryFee = calculateDeliveryFee(total);
    let subTotal = total + deliveryFee;
    let remise = 0
    if (discountCode) {
      remise = subTotal * order?.discountCode?.rate / 100;
    }
    setOrder({ ...order, subtotal: total, delivery: deliveryFee, total: total + deliveryFee, remise: remise })
  }, [cartItems, discountCode])

  return (
    <div className="d-flex align-items-center" ref={node}>
      <Navbar
        onToggle={setIsNavExpanded}
        expanded={isNavExpanded}
        color="light"
        expand="lg"
        className="navbar-light osahan-nav shadow-sm sticky flex-column"
      >
        <Container style={{ borderBottom: company ? '1px solid #EEE' : '0px' }}>
          <Navbar.Brand>
            <Link to="/">
              <div className="d-flex flex-column align-items-start">
                <Image src="/img/lunchbox-logo.png" height={50} alt="" />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav activeKey={0} className="ml-auto" onSelect={closeMenu}>
              <Nav.Link
                eventKey={0}
                as={NavLink}
                activeclassname="active"
                exact
                to="/"
              >
                Accueil <span className="sr-only">(current)</span>
              </Nav.Link>
              {/* <Nav.Link
                eventKey={1}
                as={NavLink}
                activeclassname="active"
                to="/offers"
              >
                <Icofont icon="sale-discount" /> Offers{" "}
                <Badge variant="danger">New</Badge>
              </Nav.Link> */}
              <NavDropdown title="Lunchbox" alignRight className="border-0">
                <NavDropdown.Item
                  eventKey={2.2}
                  as={NavLink}
                  activeclassname="active"
                  to="/a-propos"
                >
                  A propos de nous
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey={2.3}
                  as={NavLink}
                  activeclassname="active"
                  to="/termes"
                >
                  Termes et conditions
                </NavDropdown.Item>
              </NavDropdown>
              {!connectedClient.connected && (
                <NavDropdown
                  alignRight
                  title={
                    <DropDownTitle
                      className="d-inline-block"
                      // image="img/user/4.png"
                      // imageAlt="user"
                      // imageClass="nav-osahan-pic rounded-pill"
                      title="Mon compte"
                    />
                  }
                >
                  <NavDropdown.Item
                    eventKey={3.3}
                    as={NavLink}
                    activeclassname="active"
                    to="/login"
                  >
                    <Icofont icon="login" /> Connexion
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey={3.4}
                    as={NavLink}
                    activeclassname="active"
                    to="/register"
                  >
                    <Icofont icon="user" /> Créer mon compte
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {connectedClient.connected && (
                <NavDropdown
                  alignRight
                  title={
                    <DropDownTitle
                      className="d-inline-block"
                      // image="img/user/4.png"
                      // imageAlt="user"
                      // imageClass="nav-osahan-pic rounded-pill"
                      title="Mon compte"
                    />
                  }
                >
                  <NavDropdown.Item
                    eventKey={4.1}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/orders"
                  >
                    <Icofont icon="food-cart" /> Commandes
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey={4.5}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/addresses"
                  >
                    <Icofont icon="location-pin" /> Addresses
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey={4.5}
                    activeclassname="active"
                    onClick={() => {
                      setClient({})
                      setConnectedClient({ connected: false })
                      history.push('/')
                    }}
                  >
                    <Icofont icon="logout" /> Déconnexion
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {cartItems.length > 0 && (
                <NavDropdown
                  activeclassname="active"
                  alignRight
                  className="dropdown-cart"
                  title={
                    <DropDownTitle
                      className="d-inline-block"
                      faIcon="shopping-basket"
                      iconClass="mr-1"
                      title="Panier"
                      badgeClass="ml-1"
                      badgeVariant="success"
                      badgeValue={cartItems.length}
                    />
                  }
                >
                  <div className="dropdown-cart-top shadow-sm">
                    {
                      <CartDropdownHeader
                        className="dropdown-cart-top-header p-4"
                        title="Lunchbox"
                        subTitle="On fait le taff pour vous ! Prenez ce qu'il vous plaît, on vous fait le meilleur prix !"
                      />
                    }
                    <div className="dropdown-cart-top-body border-top p-4">
                      {cartItems.map((item, key) => (
                        <CartDropdownItem
                          key={key}
                          icoIcon="ui-press"
                          iconClass="text-success food-item"
                          title={`${item.name} x ${item.number}`}
                          price={`${item.price * item.number} DT`}
                        />
                      ))}
                    </div>
                    <div className="dropdown-cart-top-footer border-top p-4">
                      <p className="mb-0 text-secondary">
                        Frais de livraison{" "}
                        <span className="float-right text-dark">
                          {order.delivery} DT
                        </span>
                      </p>
                      {/* <small className="text-info">Extra charges may apply</small> */}
                    </div>
                    <div className="dropdown-cart-top-footer border-top p-4">
                      <p className="mb-0 font-weight-bold text-secondary">
                        Total{" "}
                        <span className="float-right text-dark">
                          {order.total} DT
                        </span>
                      </p>
                      {/* <small className="text-info">Extra charges may apply</small> */}
                    </div>
                    <div className="dropdown-cart-top-footer border-top p-2">
                      <NavDropdown.Item
                        eventKey={5.1}
                        as={Link}
                        className="btn btn-success btn-block py-3 text-white text-center dropdown-item"
                        to="/checkout"
                      >
                        {" "}
                        Procéder à la validation
                      </NavDropdown.Item>
                    </div>
                  </div>
                </NavDropdown>
              )}
              {cartItems.length === 0 && (
                <Nav.Link
                  eventKey={0}
                  as={NavLink}
                  activeclassname="active"
                  exact
                  to="#"
                >
                  <Icofont icon="cart" /> Panier
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
        {company && <div className="d-flex align-items-end my-2">
          <h4 style={{ textTransform: 'uppercase', fontWeight: 700, margin: 0 }}>{company.name}</h4>
          <p style={{ margin: 0, fontWeight: 400, marginLeft: 10, fontSize: 15 }}>{zones.find(el => el.value === company.zone).label}</p>
          <X color='#000' className="ml-5" style={{ cursor: 'pointer' }} onClick={() => {
            history.push('/');
            setCompany(null);
          }} />
        </div>}
      </Navbar>
    </div>
  )
}

export default Header
