import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const cart = atom({
  key: "cart",
  default: [],
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom]
});

export const orderAtom = atom({
  key: "order",
  default: null,
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom]
});

export const menuAtom = atom({
  key: "menu",
  default: null,
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom]
});

export const clientAtom = atom({
  key: "client",
  default: { connected: false },
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom]
});

export const codeAtom = atom({
  key: "discount-code",
  default: null,
  dangerouslyAllowMutability: true,
});

export const expiredAtom = atom({
  key: "expired",
  default: null,
  dangerouslyAllowMutability: true,
});

export const companyAtom = atom({
  key: "entreprise",
  default: null,
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom]
})