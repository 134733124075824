import axios from "axios"
import React from "react"
import { Spinner } from "react-bootstrap"
import OwlCarousel from "react-owl-carousel3"
import { useQuery } from "react-query"
import { host } from "../../config"
import ProductBox from "../home/ProductBox"

const CategoriesCarousel = () => {
  const useGetTags = () => {
    return useQuery("getTags", async () => {
      return await axios.get(`${host}/admin-api/v1/tag/list`)
    })
  }

  const { data: tagsData, isLoading } = useGetTags()
  return (
    <OwlCarousel
      nav
      loop
      {...options}
      className="owl-carousel-category owl-theme"
    >
      {tagsData &&
        tagsData.data.map((tag, key) => (
          <div key={key} className="item">
            <ProductBox
              boxClass="osahan-category-item"
              title={tag.name}
              image={`${host}/public/${tag.image}`}
              imageClass="img-fluid"
              imageAlt={tag.name}
              linkUrl="#"
            />
          </div>
        ))}
      {!tagsData && isLoading && <Spinner size="sm" />}
    </OwlCarousel>
  )
}

const options = {
  responsive: {
    0: {
      items: 3
    },
    600: {
      items: 4
    },
    1000: {
      items: 6
    },
    1200: {
      items: 8
    }
  },
  loop: true,
  lazyLoad: true,
  autoplay: true,
  dots: false,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  nav: true,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>"
  ]
}

export default CategoriesCarousel
